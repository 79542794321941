import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Accordion, Col, Container, Row } from 'react-bootstrap'
import Calendar from 'react-calendar'
import { format } from 'date-fns'

// styles
import './PublicWitnessing.css'

// assets
import s148 from '../../assets/S-148_E.pdf'
import rwcSigns from '../../assets/RWCSignsonPublicProperty.pdf'
import permissionLetter from '../../assets/permission-letter-menlo-park.pdf'

export default function PublicWitnessing() {
  const [date, setDate] = useState(new Date())
  const today = format(date, 'd')
  const navigate = useNavigate()

  const handleClick = (date) => {
    setDate(date)
    // 2022-6-16
    const y = format(date, 'y')
    const m = format(date, 'L')
    const d = format(date, 'd')

    navigate(`/public-witnessing/${y}-${m}-${d}`)
  }

  return (
    <Container className="public-witnessing mb-4">
      <h2 className="text-center mt-3 mb-2">Public Witnessing Schedule</h2>
      <p className="text-center">Please choose a date below to get started</p>
      <hr />
      <Row>
        <Col md={10} lg={8} xl={6} className="mx-auto">
          <Calendar
            onClickDay={handleClick}
            value={date}
            calendarType="US"
            showFixedNumberOfWeeks={false}
            showNeighboringMonth={false}
            minDetail="month"
            nextLabel={
              <span className="material-symbols-outlined">chevron_right</span>
            }
            prevLabel={
              <span className="material-symbols-outlined">chevron_left</span>
            }
            next2Label={null}
            prev2Label={null}
            // minDate={new Date()}
            // tileContent={'hey'}
          />
          <hr />
          <Row>
            <Col className="d-flex justify-content-center">
              <span className="legend-today">{today}</span>
              <span>Today</span>
            </Col>
            <Accordion className="my-3">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Guidelines and notes</Accordion.Header>
                <Accordion.Body>
                  <h6 className="fw-bold mb-3">
                    Important guidelines and links
                  </h6>
                  <div className="mb-4">
                    <span className="pdf-links">
                      <a href={s148}>Public Witnessing Guidelines (S-148)</a>
                    </span>
                    <span className="pdf-links">
                      <a href={rwcSigns}>RWC Signs on Public Property</a>
                    </span>
                    <span className="pdf-links">
                      <a href={permissionLetter}>
                        Fremont Park Permission letter from the City of Menlo
                        Park
                      </a>
                    </span>
                  </div>
                  <h6 className="fw-bold mb-3">Notes</h6>
                  <ul>
                    <li>
                      The code for the shed is <strong>1943</strong>. The key is
                      inside the lock box.
                    </li>
                  </ul>
                  <br />
                  <ul>
                    <li>
                      Questions about the cart or Public Witnessing - Contact
                      Bro Carranza
                    </li>
                    <li>
                      Questions about the website/app - Contact Bro Molina
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}
