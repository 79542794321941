import firebase from 'firebase/app'
import 'firebase/firestore'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyDKfExmqVwoUb-V1X2-9twGqgJV5QX93H4',
  authDomain: 'english-menlo-park.firebaseapp.com',
  projectId: 'english-menlo-park',
  storageBucket: 'english-menlo-park.appspot.com',
  messagingSenderId: '254629159023',
  appId: '1:254629159023:web:c00ebcb6171197e95cad9a',
}

// init firebase
firebase.initializeApp(firebaseConfig)

// init services
const projectFirestore = firebase.firestore()

// timestamp
const timestamp = firebase.firestore.Timestamp

export { projectFirestore, timestamp }
