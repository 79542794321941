import { Button, Card, Col, Row } from 'react-bootstrap'

export default function TimeSlot({
  location,
  timeRange,
  friends,
  handleSignUp,
  removeName,
  available,
  toggleEdit,
}) {
  // const [yy, mm, dd] = pageId.split('-')

  const cardColor = friends.length === 2 ? 'secondary' : 'primary'

  return (
    <Card border={cardColor} className="mb-4">
      <Card.Body className="d-grid">
        <Card.Title className={'mb-3 text-' + cardColor}>
          {timeRange}
        </Card.Title>
        {!available || friends[0] ? (
          <>
            {available ? (
              <Card className="border-secondary text-muted mb-2 px-0">
                <Row className="d-flex align-items-center justify-content-between">
                  <Col className="ps-5">{friends[0]}</Col>
                  <Col className="d-flex justify-content-end">
                    <Button
                      variant="outline-success border-0 rounded-0"
                      onClick={() => {
                        toggleEdit(location, timeRange, friends[0], 0)
                      }}
                    >
                      <span className="material-symbols-outlined">edit</span>
                    </Button>
                    <span className="border border-0 border-end border-secondary"></span>
                    <Button
                      variant="outline-danger border-0 rounded-0"
                      onClick={() =>
                        removeName(location, timeRange, friends[0], 0)
                      }
                    >
                      <span className="material-symbols-outlined">delete</span>
                    </Button>
                  </Col>
                </Row>
              </Card>
            ) : (
              <Button variant="outline-secondary mb-2" disabled>
                Unavailable
              </Button>
            )}
          </>
        ) : (
          <>
            <Button
              variant="outline-primary mb-2"
              onClick={() => handleSignUp(location, timeRange)}
            >
              Click to sign up!
            </Button>
          </>
        )}
        {!available || friends[1] ? (
          <>
            {available ? (
              <Card className="border-secondary text-muted mb-2 px-0">
                <Row className="d-flex align-items-center justify-content-between">
                  <Col className="ps-5">{friends[1]}</Col>
                  <Col className="d-flex justify-content-end">
                    <Button
                      variant="outline-success border-0 rounded-0"
                      onClick={() => {
                        toggleEdit(location, timeRange, friends[1], 1)
                      }}
                    >
                      <span className="material-symbols-outlined">edit</span>
                    </Button>
                    <span className="border border-0 border-end border-secondary"></span>
                    <Button
                      variant="outline-danger border-0 rounded-0"
                      onClick={() =>
                        removeName(location, timeRange, friends[1], 1)
                      }
                    >
                      <span className="material-symbols-outlined">delete</span>
                    </Button>
                  </Col>
                </Row>
              </Card>
            ) : (
              <Button variant="outline-secondary mb-2" disabled>
                Unavailable
              </Button>
            )}
          </>
        ) : (
          <>
            <Button
              variant="outline-primary mb-2"
              onClick={() => handleSignUp(location, timeRange)}
            >
              Click to sign up!
            </Button>
          </>
        )}
      </Card.Body>
    </Card>
  )
}
