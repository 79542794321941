// styles & assets
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'

export default function CustomNav() {
  return (
    <Navbar sticky="top" bg="dark" variant="dark" expand="lg">
      <Container className="px-3">
        <Navbar.Brand href="/" className="">
          English Menlo Park
        </Navbar.Brand>
        {/* <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link href="/">Public Witnessing</Nav.Link>
          </Nav>
        </Navbar.Collapse> */}
      </Container>
    </Navbar>
  )
}
