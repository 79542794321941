import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useDocument } from '../../hooks/useDocument'
import { useFirestore } from '../../hooks/useFirestore'

import { format } from 'date-fns'
import isSaturday from 'date-fns/isSaturday'
import isSunday from 'date-fns/isSunday'
import isExists from 'date-fns/isExists'

import TimeSlot from './TimeSlot'

import {
  Accordion,
  Button,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row,
  Spinner,
  Tab,
  Tabs,
  Toast,
  ToastContainer,
} from 'react-bootstrap'

// styles & files
import './PwDetails.css'
import imageFremont from '../../assets/fremont-park.png'
import imageSharonHeights from '../../assets/sharon-heights.png'
import imageWoodsidePlaza from '../../assets/woodside-plaza.png'
import permissionLetter from '../../assets/permission-letter-menlo-park.pdf'

export default function PwDetails() {
  // getting the params + setting date vars
  const { id } = useParams()
  const navigate = useNavigate()
  const [yy, mm, dd] = id.split('-')

  const thisDate = new Date(yy, parseInt(mm) - 1, dd)
  const month = format(thisDate, 'LLLL')
  const day = format(thisDate, 'EEEE')

  const db = 'Public Witnessing'
  // const db = 'Public Witnessing TEST'

  const { addDocument } = useFirestore(db)
  let { document, error } = useDocument(db, id)
  if (error) {
    // If no such document, populate document object

    document = {
      locations: [],
    }

    if (isSunday(thisDate)) {
      document.locations.push(
        {
          name: 'Fremont Park',
          spots: [
            { spot: '6-8am', friends: [] },
            { spot: '8-10am', friends: [] },
            { spot: '10-12pm', friends: [] },
            { spot: '12-2pm', friends: [] },
            { spot: '2-4pm', friends: [] },
            { spot: '4-6pm', friends: [] },
            { spot: '6-8pm', friends: [] },
          ],
        },
        {
          name: 'Sharon Heights',
          spots: [
            { spot: '6-8am', friends: [] },
            { spot: '8-10am', friends: [] },
            { spot: '10-12pm', friends: [] },
            { spot: '12-2pm', friends: [] },
            { spot: '2-4pm', friends: [] },
            { spot: '4-6pm', friends: [] },
            { spot: '6-8pm', friends: [] },
          ],
        },
        {
          name: 'Woodside Plaza',
          spots: [
            { spot: '6-8am', friends: [] },
            { spot: '8-10am', friends: [] },
            { spot: '10-12pm', friends: [] },
            { spot: '12-2pm', friends: [] },
            { spot: '2-4pm', friends: [] },
            { spot: '4-6pm', friends: [] },
            { spot: '6-8pm', friends: [] },
          ],
        }
      )
    } else {
      // normal schedule for non-sunday
      document.locations.push(
        {
          name: 'Fremont Park',
          spots: [
            { spot: '6-8am', friends: [] },
            { spot: '8-10am', friends: [] },
            { spot: '10-12pm', friends: [] },
            { spot: '12-2pm', friends: [] },
            { spot: '2-4pm', friends: [] },
            { spot: '4-6pm', friends: [] },
            { spot: '6-8pm', friends: [] },
          ],
        },
        {
          name: 'Sharon Heights',
          spots: [
            { spot: '6-8am', friends: [] },
            { spot: '8-10am', friends: [] },
            { spot: '10-12pm', friends: [] },
            { spot: '12-2pm', friends: [] },
            { spot: '2-4pm', friends: [] },
            { spot: '4-6pm', friends: [] },
            { spot: '6-8pm', friends: [] },
          ],
        },
        {
          name: 'Woodside Plaza',
          spots: [
            { spot: '6-8am', friends: [] },
            { spot: '8-10am', friends: [] },
            { spot: '10-12pm', friends: [] },
            { spot: '12-2pm', friends: [] },
            { spot: '2-4pm', friends: [] },
            { spot: '4-6pm', friends: [] },
            { spot: '6-8pm', friends: [] },
          ],
        }
      )
    }

    // Add RWC Library on Saturdays
    if (isSaturday(thisDate)) {
      // document.locations.push({
      //   name: 'Redwood City Library',
      //   spots: [
      //     { spot: '6-8am', friends: [] },
      //     { spot: '8-10am', friends: [] },
      //     { spot: '10-12pm', friends: [] },
      //     { spot: '12-2pm', friends: [] },
      //     { spot: '2-4pm', friends: [] },
      //     { spot: '4-6pm', friends: [] },
      //     { spot: '6-8pm', friends: [] },
      //   ],
      // })
    }

    // reset error
    error = null
  }

  const locationDetails = [
    {
      name: 'Fremont Park',
      address: `<a href="https://www.google.com/maps/place/University+Dr+%26+Santa+Cruz+Ave,+Menlo+Park,+CA+94025/@37.4494092,-122.1885622,17z/data=!3m1!4b1!4m5!3m4!1s0x808fa4ba846b82bd:0x1e4790a0fc1b64db!8m2!3d37.4494092!4d-122.1863735">Santa Cruz Ave & University Dr.<br />Menlo Park, CA 94025 <span class="material-symbols-outlined external-link">near_me</span></a>`,
      notes: `<ul><li><a href=${permissionLetter}>Permission letter from the City of Menlo Park</a></li></ul>`,
      image: imageFremont,
    },
    {
      name: 'Sharon Heights',
      address: `<a href="https://goo.gl/maps/khv9xerGw3mnuaL66">Corner of Sharon Park Rd and Sharon Park Dr<br />Menlo Park, CA 94025 <span class="material-symbols-outlined external-link">near_me</span></a>`,
      notes: `<ul><li>Available starting April 1</li></ul>`,
      image: imageSharonHeights,
    },
    {
      name: 'Woodside Plaza',
      address: `<a href="https://goo.gl/maps/RRejUrWaMyEczayCA">Woodside Plaza<br />Redwood City, CA 94063 <span class="material-symbols-outlined external-link">near_me</span></a>`,
      notes: `<ul><li>Available starting April 1</li></ul>`,
      image: imageWoodsidePlaza,
    },
  ]

  // const locationNames = []
  // locationDetails.forEach((l) => {
  //   let locationName = ''
  //   let locationSplit = l.name.split(' ')
  //   locationSplit.forEach((word) => (locationName += word))
  //   locationNames.push(locationName)
  // })
  // let locationHash = 0
  // if (window.location.hash) {
  //   locationHash = window.location.hash.slice(1)
  // }

  // modal and toast
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)

  const [showToast, setShowToast] = useState(false)
  const [toastText, setToastText] = useState('')

  // Form data
  const [locationData, setLocationData] = useState('')
  const [timeData, setTimeData] = useState('')
  const [name, setName] = useState('')
  const [formErrorMsg, setFormErrorMsg] = useState('')
  const [friendId, setFriendId] = useState('')
  const [friendName, setFriendName] = useState('')
  const [deleteFriendText, setDeleteFriendText] = useState('')

  const handleModal = (location, timeRange) => {
    // Update states
    setTimeData(timeRange)
    setLocationData(location)

    // Update modal
    setModalTitle(`${location} (${timeRange})`)
    setShowModal(true)
  }

  const handleClose = () => {
    setName('')
    setFormErrorMsg('')
    setShowModal(false)
  }

  const handleCloseDelete = () => {
    setName('')
    setFormErrorMsg('')
    setFriendId('')
    setDeleteFriendText('')
    setShowDeleteModal(false)
  }

  const handleCloseEdit = () => {
    setName('')
    setFriendName('')
    setFormErrorMsg('')
    setShowEditModal(false)
  }

  const triggerToast = (toastCustomText) => {
    setToastText(toastCustomText)
    setShowToast(true)
    setTimeout(() => {
      setShowToast(false)
    }, 5000)
  }

  const signUp = (e) => {
    e.preventDefault()

    if (name.trim() === '') {
      setFormErrorMsg('Please enter a name')
      return
    }

    // document
    // get the array index that matches the location name
    const locationInd = document.locations.findIndex(
      (el) => el.name === locationData
    )

    // get the spot index for this timespot in that location array
    const thisLocation = document.locations[locationInd]
    const spotInd = thisLocation.spots.findIndex((el) => el.spot === timeData)

    // now check if both spots aren't already taken
    const thisSpot = thisLocation.spots[spotInd]
    if (thisSpot.friends.length < 2) {
      // There is at least one space available - good to sign up

      // Add current friend to this array
      thisSpot.friends.push(name.trim())
    } else {
      // Error - both spots taken
    }

    try {
      // Push to firebase
      addDocument(id, document)
      triggerToast(
        `Successfully signed up for ${thisLocation.name} at \n ${thisSpot.spot}`
      )
      handleClose()
    } catch (err) {}
  }

  const toggleRemoveName = (location, timeRange, friendName, friendInd) => {
    setTimeData(timeRange)
    setLocationData(location)
    setFriendId(friendInd)

    setDeleteFriendText(
      `<p>You are removing <strong>${friendName}</strong> from the <strong>${timeRange}</strong> ${location} location.</p>
      <p class="mb-0">Remove the name?</p>`
    )

    setShowDeleteModal(true)
  }

  const toggleEditName = (location, timeRange, friend, friendInd) => {
    setTimeData(timeRange)
    setLocationData(location)
    setFriendId(friendInd)
    setFriendName(friend)

    setShowEditModal(true)
  }

  const removeFriend = () => {
    // get the array index that matches the location name
    const locationInd = document.locations.findIndex(
      (el) => el.name === locationData
    )

    // get the spot index for this timespot in that location array
    const thisLocation = document.locations[locationInd]
    const spotInd = thisLocation.spots.findIndex((el) => el.spot === timeData)

    // make a copy of the spot arr without this friend
    const friendName = thisLocation.spots[spotInd].friends[friendId]
    const newArr = thisLocation.spots[spotInd].friends.filter(
      (el, ind) => ind !== friendId
    )

    // set this array to the spot
    thisLocation.spots[spotInd].friends = newArr

    try {
      // push
      addDocument(id, document)
      triggerToast(
        `Successfully removed ${friendName} from \n ${locationData} at ${timeData}`
      )
      handleCloseDelete()
    } catch (error) {
      console.log('errrrrrror')
    }
  }

  const editFriend = (e) => {
    e.preventDefault()

    if (friendName.trim() === '') {
      setFormErrorMsg('Please enter a name')
      return
    }

    // get the array index that matches the location name
    const locationInd = document.locations.findIndex(
      (el) => el.name === locationData
    )
    // get the spot index for this timespot in that location array
    const thisLocation = document.locations[locationInd]
    const spotInd = thisLocation.spots.findIndex((el) => el.spot === timeData)

    thisLocation.spots[spotInd].friends[friendId] = friendName.trim()

    try {
      // push
      addDocument(id, document)
      triggerToast(`Successfully edited the name`)
      handleCloseEdit()
    } catch (error) {
      console.log('errrrrrror')
    }
  }

  useEffect(() => {
    const exists = isExists(parseInt(yy), parseInt(mm) - 1, parseInt(dd))
    if (!exists) {
      // back you go!
      navigate('/')
    }
  })

  return (
    <>
      {!document && !error ? (
        <Container
          className="d-flex align-items-center justify-content-center pw-details"
          style={{ height: '100vh' }}
        >
          <Spinner animation="border" variant="primary" />
        </Container>
      ) : error ? (
        <Container
          className="d-flex align-items-center justify-content-center pw-details"
          style={{ height: '100vh' }}
        >
          Oops! Something went wrong!
          <br />
          Please contact Brother Molina
        </Container>
      ) : (
        <Container className="pw-details">
          <h2 className="text-center mt-3 mb-2">
            Schedule for {day}, {month}&nbsp;{dd}
          </h2>
          <p className="my-4 text-center">
            Please click on a location and time slot to sign up
          </p>
          <Row>
            <Col md={10} lg={8} xl={6} className="mx-auto">
              <Tabs
                fill
                justify
                id="uncontrolled-tab-example"
                // activeKey={locationHash}
                // onSelect={(e) => (window.location.hash = e)}
                defaultActiveKey={document.locations[0].name}
                key={document.locations[0].name}
              >
                {document.locations.map((loc, ind) => (
                  // <Tab eventKey={ind} title={loc.name} key={loc.name}>
                  <Tab eventKey={loc.name} title={loc.name} key={loc.name}>
                    <h2 className="my-4 text-center">{loc.name}</h2>

                    <Accordion className="my-3">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Details</Accordion.Header>
                        <Accordion.Body>
                          <h6 className="fw-bold">Address</h6>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: locationDetails[ind].address,
                            }}
                          ></p>

                          {locationDetails[ind].notes && (
                            <>
                              <h6 className="fw-bold">Notes</h6>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: locationDetails[ind].notes,
                                }}
                              ></p>
                            </>
                          )}

                          <p>
                            <Image
                              fluid
                              rounded
                              style={{
                                aspectRatio: '16/9',
                                objectFit: 'cover',
                              }}
                              src={locationDetails[ind].image}
                              alt={`${loc.name} preview`}
                            />
                          </p>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <Row>
                      <Col>
                        {loc.spots.map((spot, spotInd) => (
                          <TimeSlot
                            key={spot.spot}
                            pageId={id}
                            friends={spot.friends}
                            location={loc.name}
                            timeRange={spot.spot}
                            handleSignUp={handleModal}
                            removeName={toggleRemoveName}
                            toggleEdit={toggleEditName}
                            available={
                              true
                              // loc.name === 'Fremont Park' ? false : true
                            }
                          />
                        ))}
                      </Col>
                    </Row>
                  </Tab>
                ))}
              </Tabs>
            </Col>
          </Row>
          <Modal
            show={showModal}
            onHide={handleClose}
            centered
            className="pw-details"
          >
            <Modal.Header closeButton>
              <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={signUp}>
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>
                    Enter your first initial and last name
                  </Form.Label>
                  <Form.Control
                    required={true}
                    type="text"
                    placeholder="Ex. J Smith"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value)
                    }}
                    autoFocus
                  />
                  {formErrorMsg && (
                    <div className="text-danger">{formErrorMsg}</div>
                  )}
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={signUp}>
                Sign up!
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={showDeleteModal}
            onHide={handleCloseDelete}
            centered
            className="pw-details"
          >
            <Modal.Header closeButton>
              <Modal.Title>{'Remove name?'}</Modal.Title>
            </Modal.Header>
            <Modal.Body
              dangerouslySetInnerHTML={{ __html: deleteFriendText }}
            ></Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseDelete}>
                Close
              </Button>
              <Button variant="danger" onClick={removeFriend}>
                Remove name
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={showEditModal}
            onHide={handleCloseEdit}
            centered
            className="pw-details"
          >
            <Modal.Header closeButton>
              <Modal.Title>{'Edit name'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={editFriend}>
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>Enter new name</Form.Label>
                  <Form.Control
                    required={true}
                    type="text"
                    value={friendName}
                    onChange={(e) => {
                      setFriendName(e.target.value)
                    }}
                    autoFocus
                  />
                  {formErrorMsg && (
                    <div className="text-danger">{formErrorMsg}</div>
                  )}
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseEdit}>
                Close
              </Button>
              <Button variant="success" onClick={editFriend}>
                Edit name
              </Button>
            </Modal.Footer>
          </Modal>

          <ToastContainer
            className="p-3 position-fixed"
            position={'bottom-center'}
          >
            <Toast
              autohide={true}
              bg="light"
              show={showToast}
              onClose={() => setShowToast(false)}
            >
              <Toast.Header
                closeVariant="white"
                closeButton={true}
                className="bg-success text-white"
              >
                {/* <img
                  src="holder.js/20x20?text=%20"
                  className="rounded me-2"
                  alt=""
                /> */}
                <strong className="me-auto">Success!</strong>
                {/* <small>11 mins ago</small> */}
              </Toast.Header>
              <Toast.Body>{toastText}</Toast.Body>
            </Toast>
          </ToastContainer>
        </Container>
      )}
    </>
  )
}
